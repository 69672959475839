// global callback function for JSONP form submission
// attached to window object by custom element instance
function mcSubscribeCallback({ result }) {
    const signupEl = document.querySelector("newsletter-signup");
    signupEl?.setAttribute("data-response", result);
  }
  
  export default class NewsletterSignup extends HTMLElement {
    static define() {
      customElements.define("newsletter-signup", this);
    }
  
    static observedAttributes = ["data-response"];
  
    constructor() {
      super();
    }
  
    get form() {
      return this.querySelector("form");
    }
  
    get output() {
      return this.querySelector("output");
    }
  
    get emailInput() {
      return this.querySelector("#mce-EMAIL");
    }
  
    get emailInputError() {
      return this.querySelector("#mce-EMAIL-error");
    }

    get lnameInput() {
        return this.querySelector("#mce-LNAME");
      }
    
    get lnameInputError() {
        return this.querySelector("#mce-LNAME-error");
    }

    get fnameInput() {
        return this.querySelector("#mce-FNAME");
      }
    
    get fnameInputError() {
        return this.querySelector("#mce-FNAME-error");
    }
  
    get emailValidityMessage() {
      const validity = this.emailInput.validity;
  
      if (validity.valid) return "";
      if (validity.typeMismatch) return "Please enter a valid e-mail address.";
      if (validity.valueMissing) return "This is a required field.";
      // If all else fails, return a generic error
      return "The value you entered for this field is invalid.";
    }

    get fnameValidityMessage() {
        const validity = this.fnameInput.validity;
    
        if (validity.valid) return "";
        if (validity.valueMissing) return "This is a required field.";
        // If all else fails, return a generic error
        return "The value you entered for this field is invalid.";
    }

    get lnameValidityMessage() {
        const validity = this.lnameInput.validity;
    
        if (validity.valid) return "";
        if (validity.valueMissing) return "This is a required field.";
        // If all else fails, return a generic error
        return "The value you entered for this field is invalid.";
    }
  
    connectedCallback() {
      if (!this.form) return;
  
      this.form.addEventListener("submit", this.handleSubmit);
  
      if (!window.mcSubscribeCallback) {
        window.mcSubscribeCallback = mcSubscribeCallback;
      }
    }
  
    disconnectedCallback() {
      if (!this.form) return;
  
      this.form.removeEventListener("submit", this.handleSubmit);
    }
  
    attributeChangedCallback(name, _, newValue) {
      if (name === "data-response") {
        if (newValue === "success" || newValue === "error") {
          this.output.className = newValue;
        }
  
        if (newValue === "error") {
          this.output.innerText = "There was an error submitting the form."
        } else if (newValue === "success") {
          this.output.innerText = "Thank you for subscribing.";
          // Hide the form after successful submission
          this.form.style.display = "none";
        }
      }
    }
  
    // MC subscribe endpoints don't support CORS, so we have to use JSONP
    handleSubmit = (event) => {
      event.preventDefault();
  
      this.output.innerText = "";
      this.output.className = "";
  
      this.emailInputError.innerText = this.emailValidityMessage;
      this.fnameInputError.innerText = this.fnameValidityMessage;
      this.lnameInputError.innerText = this.lnameValidityMessage;
      
      if (!this.emailInput.validity.valid || !this.fnameInput.validity.valid || !this.lnameInput.validity.valid) {
        return;
      }
  
      const url = new URL(event.target.action);
      url.searchParams.set("c", "mcSubscribeCallback");
  
      const formData = new FormData(event.target);
      for (const [key, value] of formData.entries()) {
        if (value) {
          url.searchParams.set(key, value);
        }
      }
  
      // Create script with url and callback (if specified)
      const script = window.document.createElement("script");
      script.src = url.toString();
  
      // Insert script tag into the DOM
      const ref = window.document.getElementsByTagName("script")[0];
      ref.parentNode.insertBefore(script, ref);
  
      // After the script is loaded (and executed), remove it
      script.onload = function () {
        this.remove();
      };
    };
  }
  